//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-928:_4856,_6628,_1080,_1664,_6092,_4880,_7644,_5756;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-928')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-928', "_4856,_6628,_1080,_1664,_6092,_4880,_7644,_5756");
        }
      }catch (ex) {
        console.error(ex);
      }